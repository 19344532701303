<script setup lang="ts">

import {usePageContext} from "vike-vue/usePageContext";
import {onMounted} from "vue";
import Swiper from "swiper";
import {Navigation, Grid, Scrollbar, Autoplay} from "swiper/modules";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/grid';
import 'swiper/css/scrollbar';

const pageContext = usePageContext();

const {banners} = pageContext.data;

onMounted(()=>{
    const swiper = new Swiper(".swiper", {
        modules: [Navigation, Grid, Scrollbar, Autoplay],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 'auto',  // Số slide tự động theo kích thước màn hình
        spaceBetween: 10,       // Khoảng cách giữa các slide
        loop: false,             // Vòng lặp slide
        freeMode: true,         // Cho phép cuộn tự do
        centeredSlides: false,  // Không căn giữa slide
        grid: {
            rows: 2,
            fill: "row"
        },
        scrollbar: {
            el: '.swiper-scrollbar', // Chỉ định thanh scrollbar
            draggable: true, // Cho phép kéo scrollbar
        },
        autoplay: {
            delay: 5000, // Tự động scroll sau 2 giây
            disableOnInteraction: true, // Không dừng khi người dùng tương tác
        },
    })

})

</script>

<template>
<div c2c-0 class="">
    <div c2c-1 class="cc
                ^ >>img w-100%-max:2048
                ^ >2 >.desktop block
                ^ <2 >.mobile block">
        <div c2c-2 class="desktop none">
            <img c2c-3 class="w-100%-max:2048 block" src="/home/home-information-promotion-banner.webp" alt="Custom team banners for baseball, softball, and soccer – Starting at $69.99 with free mini banners. Design your own with our online tool today!">
        </div>
        <div c2c-4 class="mobile none">
            <img c2c-5 class="w-100%-max:2048 block" src="/home/free-mini-banners-promotion-mobile.webp" alt="Design your own custom softball team banner with our easy-to-use online tool. High-quality 13oz vinyl banners with vibrant printing.">
        </div>
    </div>

    <div c2c-6 class="bg-0" v-if="banners">
        <ul c2c-7 class="">
            <li v-for="category in banners" :key="category._id">
                <div c2c-8 class="fs-25 tb tc p-12-12-0-12">
                    <p>
                        {{category.alias}}
                        <span c2c-9 class="c-red">$69.99</span>
                    </p>
                    <p c2c-10 class="fs-13 c-red">+Free mini banners</p>
                </div>
                <div c2c-11 class="p-12-12">
                    <div c2c-12 class="swiper">
                        <ul c2c-13 class="swiper-wrapper >li w-300
                            ^ <2 >li w-46vw">
                            <li c2c-14 class="swiper-slide" v-for="design in category.designs" :key="design._id">
                                <a c2c-15 class="inline-block" :href="`/team-banner/${design._id}`">
                                    <img c2c-16 class="w-100% br-6" :src="design.img_url || design.jpg_url" :alt="design.alt"/>
                                </a>
                            </li>
                        </ul>
                        <!-- If we need navigation buttons -->
                        <div c2c-17 class="swiper-button-prev"></div>
                        <div c2c-18 class="swiper-button-next"></div>

                        <!-- If we need scrollbar -->
                        <div c2c-19 class="swiper-scrollbar"></div>
                    </div>
                </div>

                <div c2c-20 class="tr p-0-12">
                    <a c2c-21 class="u i" :href="category.url">View more</a>
                </div>


            </li>
        </ul>
    </div>

    <div c2c-22 class="cc bg-0 p-b-20">
        <div c2c-23 class="w-100%-max:1200 p-30-12-12-12
                    ^ >3 rm rc >div:2 p-l-50
                    ^ <3 crt crc >div:2 p-b-30">
            <div c2c-24 class="<3 p-t-12">
                <div c2c-25 class="bd sd-0-0-10-5-shadow br-20">
                    <a href="/design-your-own">
                        <img c2c-26 class="br-12 block ^ >3 h-300 ^ <3 w-100%" src="/banners/desgn-your-own.webp" alt="team banner sports design your own">
                    </a>

                </div>
                <div c2c-27 class="fs-30 tc fw-9 c-logo m-t-12">
                    <a c2c-28 class="c-logo-l:-15" href="/design-your-own">
                        🎨 Design & Order Your Custom Team Banner Today!
                    </a>

                </div>
            </div>

            <div c2c-29 class="m_end tj
                        ^ <3 >h1 fs-19 c-1
                        ^ >3 >h1 fs-24">
                <h1>WELCOME TO TEAM BANNER SPORTS</h1>
                <p>At Team Banner Sports, we specialize in high-quality, full-color sports banners designed to bring pride and energy to your team. Whether it’s soccer, baseball, softball, basketball, or any sport, our durable and vibrant banners will make your team stand out on game day!</p>
                <p c2c-30 class="fw-9">
                    WHY CHOOSE TEAM BANNER SPORTS?
                </p>
                <div c2c-31 class="m_end ^ >>span c-green">
                    <p>
                        <span c2c-32 class="icon-ok-squared"></span>
                        Premium Quality & Weather-Resistant Materials – Built to last for indoor and outdoor use.
                    </p>
                    <p>
                        <span c2c-33 class="icon-ok-squared"></span>
                        Bright, High-Resolution Digital Printing – Crisp, vivid colors that make an impact.
                    </p>
                    <p>
                        <span c2c-34 class="icon-ok-squared"></span>
                        Easy Online Customization – Use our intuitive banner builder tool to design your own banner!
                    </p>
                    <p>
                        <span c2c-35 class="icon-ok-squared"></span>
                        Fast & Reliable Nationwide Shipping – Get your banner delivered quickly, just in time for the big game!
                    </p>
                </div>
                <p c2c-36 class="fw-9"> 🚀 Shipping Times:</p>
                <div c2c-37 class="">
                    <p>📦 California: 1-3 business days</p>
                    <p>📦 Other States: 3-6 business days</p>
                </div>
            </div>
        </div>
    </div>



    <div c2c-38 class="cc bg-0">
        <div c2c-39 class="rw m-tb-20
                    w-100%-max:1200
                    ^ >>img w-200
                    ^ >div tc
                    ^ >>.title fw-9 fs-25
                    ^ >>.price fs-20 fw-9 >>.price>span:2 c-red m-l-6 fs-16
                    ^ >3 >div br-12 bg-0 p-12
                    ^ <3 cc

">
            <div c2c-40 class="m_end">
                <a c2c-41 class="block" href="/softball-banners">
                    <img c2c-42 class="" src="/banners/softball-banners.webp" alt="softball team banners">
                </a>
                <p>
                    <a c2c-43 class="title" href="/softball-banners">SOFTBALL BANNERS</a>
                </p>

                <p c2c-44 class="price">
                    <span>$69.99</span>
                    <span c2c-45 class="lt">$89.99</span>
                </p>
                <p>
                    UV Printed 13 oz. Matte Vinyl Banner
                </p>
                <p>
                    <span>Size: 5ft x 3ft (60" x 36")</span>
                </p>
                <div c2c-46 class="m-t-6 m_end">
                    <p c2c-47 class="fs-17 i">Choose from available templates:</p>
                    <ul c2c-48 class="u tb m_end">
                        <li>
                            <a href="/softball-banners/hem-grommets-softball-banners">Hem & Grommets</a>

                        </li>
                        <li>
                            <a href="/softball-banners/pole-pocket-softball-banners">Pole Pocket</a>
                        </li>
<!--                        <li>Triangle Pennant</li>-->
<!--                        <li>Home Plate Pennant</li>-->
                    </ul>
                </div>

            </div>
            <div c2c-49 class="m_end">
                <a c2c-50 class="block" href="/baseball-banners">
                    <img c2c-51 class="" src="/banners/baseball-banners.webp" alt="baseball team banners">
                </a>
                <p>
                    <a c2c-52 class="title" href="/baseball-banners">BASEBALL BANNERS</a>
                </p>
                <p c2c-53 class="price">
                    <span>$69.99</span>
                    <span c2c-54 class="lt">$89.99</span>
                </p>
                <p>
                    UV Printed 13 oz. Matte Vinyl Banner</p>
                <p>
                    <span>Size: 5ft x 3ft (60" x 36")</span>
                </p>
                <div c2c-55 class="m-t-6 m_end">
                    <p c2c-56 class="fs-17 i">Choose from available templates:</p>
                    <ul c2c-57 class="u tb m_end">
                        <li>
                            <a href="/baseball-banners/hem-grommets-baseball-banners">Hem & Grommets</a>
                        </li>
                        <li>
                            <a href="/baseball-banners/pole-pocket-baseball-banners">Pole Pocket</a>
                        </li>
<!--                        <li>Triangle Pennant</li>-->
<!--                        <li>Home Plate Pennant</li>-->
                    </ul>
                </div>

            </div>
            <div c2c-58 class="m_end">
                <a c2c-59 class="block" href="/soccer-banners">
                    <img c2c-60 class="" src="/banners/soccer-banners.webp" alt="soccer banners">
                </a>
                <p>
                    <a c2c-61 class="title block"href="/soccer-banners">
                        SOCCER BANNERS
                    </a>
                </p>
                <p c2c-62 class="price">
                    <span>$69.99</span>
                    <span c2c-63 class="lt">$89.99</span>
                </p>
                <p>
                    UV Printed 13 oz. Matte Vinyl Banner</p>
                <p>
                    <span>Size: 5ft x 3ft (60" x 36")</span>
                </p>
                <div c2c-64 class="m-t-6 m_end">
                    <p c2c-65 class="fs-17 i">Choose from available templates:</p>
                    <ul c2c-66 class="u tb m_end">
                        <li>
                            <a href="/soccer-banners/hem-grommets-soccer-banners">
                                Hem & Grommets
                            </a>
                        </li>
                        <li>
                            <a href="/soccer-banners/pole-pocket-soccer-banners">Pole Pocket</a>
                        </li>
<!--                        <li>Triangle Pennant</li>-->
<!--                        <li>Home Plate Pennant</li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div c2c-67 class="tc cc p-12">
        <p c2c-68 class="tb m-t-12 fs-25">Easy Online Tool for Creating Sports Team Banners</p>
        <img c2c-69 class="w-100%-max:960" src="/home/design-tool-team-banners.gif" alt="Design and personalize your softball, baseball, or soccer team banner with our easy-to-use online tool">
    </div>


<!--    <div c2c-70 class="p-12">-->
<!--        When purchasing pennants, please note that we only print rectangular banners and do not offer cutting services for individual shapes like triangles. Your order will be printed as a single large banner containing multiple pennants, which you can cut yourself if needed. This allows us to offer the best possible pricing.-->
<!--    </div>-->



</div>
</template>

<style >
/* Thay đổi màu của icon mũi tên */
.swiper-button-prev::after,
.swiper-button-next::after {
    color: white; /* Màu icon */
    font-size: 24px; /* Kích thước icon */
}

/* Thay đổi màu nền của nút */
.swiper-button-prev,
.swiper-button-next {
    background-color: rgba(0, 0, 0, 0.5); /* Màu nền có độ trong suốt */
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Làm tròn nút */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Hover effect */
.swiper-button-prev:hover,
.swiper-button-next:hover {
    background-color: #007bff; /* Màu nền khi hover */
}

/* Tuỳ chỉnh scrollbar */
.swiper-scrollbar {
    background: rgba(0, 0, 0, 0.1); /* Màu nền scrollbar */
    height: 5px; /* Độ dày của thanh scrollbar */
}

.swiper-scrollbar-drag {
    background: #ff0007; /* Màu thanh trượt */
    border-radius: 5px;
}
</style><style lang="scss" scoped>.cc[c2c-1] {
                        display: flex; flex-direction: column; align-items: center;
                 }.\>\>img.w-100\%-max\:2048[c2c-1] img {
                        width: 100%;max-width: 2048px;
                 }@media (min-width: 768px) {.\>2.\>\.desktop.block[c2c-1] > .desktop {
                        display: block;
                 }}@media (max-width: 768px) {.\<2.\>\.mobile.block[c2c-1] > .mobile {
                        display: block;
                 }}.none[c2c-2] {
                        display: none;
                 }.w-100\%-max\:2048[c2c-3] {
                        width: 100%;max-width: 2048px;
                 }.block[c2c-3] {
                        display: block;
                 }.none[c2c-4] {
                        display: none;
                 }.w-100\%-max\:2048[c2c-5] {
                        width: 100%;max-width: 2048px;
                 }.block[c2c-5] {
                        display: block;
                 }.bg-0[c2c-6] {
                        background-color: hsl(0, 0%, 100%);
                 }.fs-25[c2c-8] {
                        font-size: 25px;
                 }.tb[c2c-8] {
                        font-weight: bold;
                 }.tc[c2c-8] {
                        text-align: center;
                 }.p-12-12-0-12[c2c-8] {
                        padding-top: 12px;padding-right: 12px;padding-bottom: 0;padding-left: 12px;
                 }.c-red[c2c-9] {
                        color: hsl(0, 100%, 50%);
                 }.fs-13[c2c-10] {
                        font-size: 13px;
                 }.c-red[c2c-10] {
                        color: hsl(0, 100%, 50%);
                 }.p-12-12[c2c-11] {
                        padding-top: 12px;padding-right: 12px;padding-bottom: 12px;padding-left: 12px;
                 }.\>li.w-300[c2c-13] > li {
                        width: 300px;
                 }@media (max-width: 768px) {.\<2.\>li.w-46vw[c2c-13] > li {
                        width: 46vw;
                 }}.inline-block[c2c-15] {
                        display: inline;
                 }.w-100\%[c2c-16] {
                        width: 100%;
                 }.br-6[c2c-16] {
                        border-top-left-radius: 6px;border-top-right-radius: 6px;border-bottom-right-radius: 6px;border-bottom-left-radius: 6px;
                 }.tr[c2c-20] {
                        text-align: right;
                 }.p-0-12[c2c-20] {
                        padding-top: 0;padding-right: 12px;padding-bottom: 0;padding-left: 12px;
                 }.u[c2c-21] {
                        text-decoration: underline;
                 }.i[c2c-21] {
                        font-style: italic;
                 }.cc[c2c-22] {
                        display: flex; flex-direction: column; align-items: center;
                 }.bg-0[c2c-22] {
                        background-color: hsl(0, 0%, 100%);
                 }.p-b-20[c2c-22] {
                        padding-bottom: 20px;
                 }.w-100\%-max\:1200[c2c-23] {
                        width: 100%;max-width: 1200px;
                 }.p-30-12-12-12[c2c-23] {
                        padding-top: 30px;padding-right: 12px;padding-bottom: 12px;padding-left: 12px;
                 }@media (min-width: 992px) {.\>3.rm[c2c-23] {
                        display: flex; flex-direction: row; align-items: center;
                 }}@media (min-width: 992px) {.\>3.rc[c2c-23] {
                        display: flex; flex-direction: row; justify-content: center;
                 }}@media (min-width: 992px) {.\>3.\>div\:2.p-l-50[c2c-23] > div:nth-child(2) {
                        padding-left: 50px;
                 }}@media (max-width: 992px) {.\<3.crt[c2c-23] {
                        display: flex; flex-direction: column-reverse; justify-content: flex-end;
                 }}@media (max-width: 992px) {.\<3.crc[c2c-23] {
                        display: flex; flex-direction: column-reverse; align-items: center;
                 }}@media (max-width: 992px) {.\<3.\>div\:2.p-b-30[c2c-23] > div:nth-child(2) {
                        padding-bottom: 30px;
                 }}@media (max-width: 992px) {.\<3.p-t-12[c2c-24] {
                        padding-top: 12px;
                 }}.bd[c2c-25] {
                        border-width: 1px; border-style: solid; border-color: hsla(0, 0%, 72.5%, 0.7)
                 }.sd-0-0-10-5-shadow[c2c-25] {
                        box-shadow: 0 0 10px 5px rgba(0,0,0,0.2)
                 }.br-20[c2c-25] {
                        border-top-left-radius: 20px;border-top-right-radius: 20px;border-bottom-right-radius: 20px;border-bottom-left-radius: 20px;
                 }.br-12[c2c-26] {
                        border-top-left-radius: 12px;border-top-right-radius: 12px;border-bottom-right-radius: 12px;border-bottom-left-radius: 12px;
                 }.block[c2c-26] {
                        display: block;
                 }@media (min-width: 992px) {.\>3.h-300[c2c-26] {
                        height: 300px;
                 }}@media (max-width: 992px) {.\<3.w-100\%[c2c-26] {
                        width: 100%;
                 }}.fs-30[c2c-27] {
                        font-size: 30px;
                 }.tc[c2c-27] {
                        text-align: center;
                 }.fw-9[c2c-27] {
                        font-weight: 900;
                 }.c-logo[c2c-27] {
                        color: hsl(197.39999999999998, 95.9%, 38%);
                 }.m-t-12[c2c-27] {
                        margin-top: 12px;
                 }.c-logo-l\:-15[c2c-28] {
                        color: hsl(197.39999999999998, 95.9%, 23%);
                 }.tj[c2c-29] {
                        text-align: justify;
                 }@media (max-width: 992px) {.\<3.\>h1.fs-19[c2c-29] > h1 {
                        font-size: 19px;
                 }}@media (max-width: 992px) {.\<3.\>h1.c-1[c2c-29] > h1 {
                        color: hsl(0, 0%, 0%);
                 }}@media (min-width: 992px) {.\>3.\>h1.fs-24[c2c-29] > h1 {
                        font-size: 24px;
                 }}.fw-9[c2c-30] {
                        font-weight: 900;
                 }.\>\>span.c-green[c2c-31] span {
                        color: hsl(120, 100%, 25.1%);
                 }.fw-9[c2c-36] {
                        font-weight: 900;
                 }.cc[c2c-38] {
                        display: flex; flex-direction: column; align-items: center;
                 }.bg-0[c2c-38] {
                        background-color: hsl(0, 0%, 100%);
                 }.rw[c2c-39] {
                        display: flex; flex-direction: row; justify-content: space-between;
                 }.m-tb-20[c2c-39] {
                        margin-top: 20px;margin-bottom: 20px;
                 }.w-100\%-max\:1200[c2c-39] {
                        width: 100%;max-width: 1200px;
                 }.\>\>img.w-200[c2c-39] img {
                        width: 200px;
                 }.\>div.tc[c2c-39] > div {
                        text-align: center;
                 }.\>\>\.title.fw-9[c2c-39] .title {
                        font-weight: 900;
                 }.\>\>\.title.fs-25[c2c-39] .title {
                        font-size: 25px;
                 }.\>\>\.price.fs-20[c2c-39] .price {
                        font-size: 20px;
                 }.\>\>\.price.fw-9[c2c-39] .price {
                        font-weight: 900;
                 }.\>\>\.price\>span\:2.c-red[c2c-39] .price > span:nth-child(2) {
                        color: hsl(0, 100%, 50%);
                 }.\>\>\.price\>span\:2.m-l-6[c2c-39] .price > span:nth-child(2) {
                        margin-left: 6px;
                 }.\>\>\.price\>span\:2.fs-16[c2c-39] .price > span:nth-child(2) {
                        font-size: 16px;
                 }@media (min-width: 992px) {.\>3.\>div.br-12[c2c-39] > div {
                        border-top-left-radius: 12px;border-top-right-radius: 12px;border-bottom-right-radius: 12px;border-bottom-left-radius: 12px;
                 }}@media (min-width: 992px) {.\>3.\>div.bg-0[c2c-39] > div {
                        background-color: hsl(0, 0%, 100%);
                 }}@media (min-width: 992px) {.\>3.\>div.p-12[c2c-39] > div {
                        padding-top: 12px;padding-right: 12px;padding-bottom: 12px;padding-left: 12px;
                 }}@media (max-width: 992px) {.\<3.cc[c2c-39] {
                        display: flex; flex-direction: column; align-items: center;
                 }}.block[c2c-41] {
                        display: block;
                 }.lt[c2c-45] {
                        text-decoration: line-through;
                 }.m-t-6[c2c-46] {
                        margin-top: 6px;
                 }.fs-17[c2c-47] {
                        font-size: 17px;
                 }.i[c2c-47] {
                        font-style: italic;
                 }.u[c2c-48] {
                        text-decoration: underline;
                 }.tb[c2c-48] {
                        font-weight: bold;
                 }.block[c2c-50] {
                        display: block;
                 }.lt[c2c-54] {
                        text-decoration: line-through;
                 }.m-t-6[c2c-55] {
                        margin-top: 6px;
                 }.fs-17[c2c-56] {
                        font-size: 17px;
                 }.i[c2c-56] {
                        font-style: italic;
                 }.u[c2c-57] {
                        text-decoration: underline;
                 }.tb[c2c-57] {
                        font-weight: bold;
                 }.block[c2c-59] {
                        display: block;
                 }.block[c2c-61] {
                        display: block;
                 }.lt[c2c-63] {
                        text-decoration: line-through;
                 }.m-t-6[c2c-64] {
                        margin-top: 6px;
                 }.fs-17[c2c-65] {
                        font-size: 17px;
                 }.i[c2c-65] {
                        font-style: italic;
                 }.u[c2c-66] {
                        text-decoration: underline;
                 }.tb[c2c-66] {
                        font-weight: bold;
                 }.tc[c2c-67] {
                        text-align: center;
                 }.cc[c2c-67] {
                        display: flex; flex-direction: column; align-items: center;
                 }.p-12[c2c-67] {
                        padding-top: 12px;padding-right: 12px;padding-bottom: 12px;padding-left: 12px;
                 }.tb[c2c-68] {
                        font-weight: bold;
                 }.m-t-12[c2c-68] {
                        margin-top: 12px;
                 }.fs-25[c2c-68] {
                        font-size: 25px;
                 }.w-100\%-max\:960[c2c-69] {
                        width: 100%;max-width: 960px;
                 }.p-12[c2c-70] {
                        padding-top: 12px;padding-right: 12px;padding-bottom: 12px;padding-left: 12px;
                 }</style>